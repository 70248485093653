import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? '#110d2e'
            : theme.palette.grey[800],
        p: 6,
        color:"whitesmoke",
        padding: '50px 0px 0px 75px', //top, right, bottom, left
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="whitesmoke" gutterBottom>
              <Link href="www.seismcorp/about" underline="hover">
              About Us
              </Link>
            </Typography>
            <Typography variant="body2" color="whitesmoke">
             SEISM Corp
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="whitesmoke" gutterBottom>
              Contact Us
            </Typography>
            <Typography variant="body2" color="whitesmoke">
              
            </Typography>
            <Typography variant="body2" color="whitesmoke">
              Email: <a>info@seism-corp.com</a>
            </Typography>
            <Typography variant="body2" color="whitesmoke">
              Phone: Comming soon
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="whitesmoke" gutterBottom>
              Follow Us
            </Typography>
            <Link href="https://www.facebook.com/" color="inherit">
              <Facebook />
            </Link>
            <Link
              href="https://www.instagram.com/"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
            >
              <Instagram />
            </Link>
            <Link href="https://www.twitter.com/" color="inherit">
              <Twitter />
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="whitesmoke" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://seismcorp.com/">
              SEISM Corp
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}