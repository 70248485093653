import { Alert, Spin } from 'antd';

function Spinner() {
	return (
		<div style={{
			flex: 1,
			justifyContent: 'center',
			alignItems:'center',
			padding:240
		}}>
			<Spin tip="Loading...">
			<Alert
				message="Loading the requested page"
				description="Please be patient while we build the content of the page to display"
				type="info"
			/>
    	</Spin>
		</div>
	);
}

export default Spinner;