import React, {lazy, Suspense} from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import { HistoryOutlined, FundProjectionScreenOutlined, FileZipOutlined, BankOutlined, StockOutlined, NotificationOutlined, HomeOutlined, DeploymentUnitOutlined, MailOutlined, ShopOutlined, CoffeeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Layout,Menu } from 'antd';
import Spinner from "./Components/Spinner";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CurrencyBitcoinOutlinedIcon from '@mui/icons-material/CurrencyBitcoinOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import Footer from './Components/Footer';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const Home = lazy(() => import('./Pages/Home'));
const About = lazy(() => import('./Pages/StaticPages/About'));
const NoMatch = lazy(() => import('./Components/NoMatch'));
const IcoSeismToken = lazy(()=> import('./Pages/CryptoRelated/IcoSeismToken'));
const CryptoDashboard = lazy(()=> import('./Pages/CryptoRelated/CryptoDashboard'));
const ContactForm = lazy(() => import('./Pages/StaticPages/ContactUs'));
const TutosBrowser = lazy(() => import('./Pages/Tutorials/TutosBrowser'));
const Spin = lazy(() => import('./Components/Spinner'));
const SelectedCryptoCurrencyDetails = lazy(() => import('./Pages/CryptoRelated/SelectedCryptoCurrencyDetails'));
const CryptoNewsFromGoogle = lazy(()=> import('./Pages/CryptoRelated/CryptoNewsFromGoogle'));
const FinanceNewsFromGoogle = lazy(()=> import('./Pages/CryptoRelated/FinanceNewsFromGoogle'));

const drawerWidth = 240;
const navItems = [
	{name:'Home', link:'/', icon:<HomeOutlinedIcon/>}, 
	{name:'About', link:'/about', icon:<InfoOutlinedIcon/>}, 
	{name:'Contact', link:'/contact-us', icon:<EmailOutlinedIcon/>}];

  
const App = (props) => {

	const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
              SEISM Corp
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<>
		<Box sx={{ display: 'flex' }}>
      <CssBaseline />
	  <AppBar style={{background:'#110d2e'}} component="nav">
        <Toolbar>
		<NavLink to='/'>
					<img
					href="/"
					alt=""
					src="/whitelogonobackground.svg"
					width="70"
					height="50"
					/>
				
				</NavLink>
          <Typography
            variant="h5"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <NavLink 
					to='/'
					style={{color:'whitesmoke', fontWeight:'bold', fontFamily:'inherit'}}  
			>
				SEISM Corp
			</NavLink>
          </Typography>
		  
          <Box sx={{ display: { xs: 'none', sm: 'block' }}}>
            {navItems.map((item) => (
				<NavLink  to={item.link} style={{color:'whitesmoke'}}>
              <Button key={item.name} sx={{ color: '#fff', margin:1}} variant='outlined' startIcon = {item.icon}>
			  {item.name}
              </Button>
			  </NavLink>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 0 }} style={{width:'100%'}}>
		
		<Sider collapsed style={{ position: "fixed", width:15, height:'100vh', padding: '60px 0px 0px 0px'}}>
				
			<Menu mode="vertical" theme="dark" defaultSelectedKeys={["1"]} style={{height:'100vh'}}>
				
				<Menu.Item key="1" icon={<HomeOutlined />}>
				<NavLink to="/">Home</NavLink>
				</Menu.Item>
				<SubMenu key="SubMenu" icon={<DeploymentUnitOutlined />} title="Our DApps">
				<Menu.Item key="sub:12" icon={<StockOutlined />}><NavLink to="/Crypto Explorer">Crypto Explorer</NavLink></Menu.Item>
				<Menu.ItemGroup title="Ongoing ICOs">
					<Menu.Item key="sub:21" icon={<BankOutlined />}><NavLink to="/ICO SEISM Token">SEISM Token ICO</NavLink></Menu.Item>
				</Menu.ItemGroup>
				</SubMenu>
				
				<Menu.Item key="4" icon={<SavingsOutlinedIcon />}>
					<NavLink to="/latest-finance-news">Latest Finance news</NavLink>
				</Menu.Item>
				<Menu.Item key="5" icon={<CurrencyBitcoinOutlinedIcon />}>
					<NavLink to="/latest-crypto-news">Latest Crypto News</NavLink>
				</Menu.Item>
				<Menu.Item key="7" icon={<MailOutlined />}>
					<NavLink to="/contact-us">Contact Us</NavLink>
				</Menu.Item>
				<Menu.Item key="8" icon={<QuestionCircleOutlined />}>
					<NavLink to="/about">About</NavLink>
				</Menu.Item>
				
			</Menu>
			
			</Sider>
		
				
			<Content
			style={{
				padding: '65px 0px 10px 90px', //top, right, bottom, left
				margin: 0,
				minHeight: 280, /*   the content styling is happening there :)*/
			  }}>
				
				<Suspense fallback={<Spinner/>}>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/about" element={<About />} />
						<Route path="*" element={<NoMatch />} />
						<Route path="/ICO SEISM Token" element={<IcoSeismToken/>} />
						<Route path="/Crypto Explorer" element={<CryptoDashboard/>} />
						<Route path="/contact-us" element={<ContactForm/>}/>
						<Route path="/tutos-browser" element={<TutosBrowser/>}/>
						<Route path="/spin" element={<Spin/>}/>
						<Route path="/crypto-details" element={<SelectedCryptoCurrencyDetails/>}/>
						<Route path="/latest-crypto-news" element={<CryptoNewsFromGoogle/>}/>
						<Route path="/latest-finance-news" element={<FinanceNewsFromGoogle/>}/>
					</Routes>
				</Suspense>
					
			</Content>
			</Box>
			
			</Box>
			<Footer style={{
				padding: '0px 0px 0px 105px', //top, right, bottom, left
				margin: 0,
				minHeight: 280, /*   the content styling is happening there :)*/
			  }}></Footer>
		</>
	);
};

export default App;